<template>
  <div class="tree tree-container">
    <el-tree
       ref="list"
      class="tree"
      :data="treedata"
      :props="defaultProps"
      accordion
      default-expand-all
      node-key="Id"
      :highlight-current="true"
      :expand-on-click-node="false"
      @node-click="handleNodeClick"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ data.TopicName }}</span>
      </span>
    </el-tree>
  </div>
</template>
<script>
import api from "../api/url.js"
import { mapState } from "vuex";
export default {
  data() {
    return {
      treedata: [],
      defaultProps: {
        children: "topicChild",
        label: "TopicName",
      },
    };
  },
  computed: {
    ...mapState(["projectId","Key"]),
    },
  created(){
     this.tree()
  },
  mounted(){
  },
  methods: {

    handleNodeClick(data) {
      this.$emit('projectId-child', data.Id )
      this.$emit('projectId-key', data.Key )
      this.$store.commit("nodeKey",data.Key)
      this.$store.commit("nodekeydata", data.Id);
      console.log(data);
    },
     tree(){
       let parame=this.$store.state.treeId==""?sessionStorage.getItem("treeId"):this.$store.state.treeId
        // let parame=this.projectId==""?sessionStorage.getItem("projectId"):this.projectId
       // let parame = this.projectId
        api.treetopicData(parame).then(res=>{
          console.log(res,"1111")
            if(res.data.Status==1){
                this.treedata.push(res.data.Entity)
                this.$nextTick(() => {	//获取树形控件方法中添加
                 let nodekey=this.nodekey==""?this.nodekey:sessionStorage.getItem("nodekey")
                 this.$refs.list.setCurrentKey(nodekey); //数据请求成功后，设置默认current
                });
            }else{
                alert(res.data.Message)
            }
        })
    }
  },
};
</script>
<style lang="scss" scoped>
.tree /deep/.custom-tree-node {
  padding-left: 10px;
}
.tree /deep/.el-tree-node__content {
  padding: 10px 5px 10px 0 !important;
  word-break: break-all;
  word-wrap: break-word;
}
/* 树形结构节点添加连线 */
.tree /deep/ .el-tree-node {
  position: relative;
  padding-left: 16px;
  white-space: normal;
}

.tree /deep/ .el-tree-node__children {
  padding-left: 16px;
}

.tree /deep/ .el-tree-node :last-child:before {
  height: 38px;
}

.tree /deep/ .el-tree > .el-tree-node:before {
  border-left: none;
}

.tree-container /deep/ .el-tree > .el-tree-node:after {
  border-top: none;
}

.tree /deep/ .el-tree-node:before {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.tree /deep/ .el-tree-node:after {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}
.tree /deep/ .el-tree-node__expand-icon.is-leaf {
  display: none;
}

.tree /deep/ .el-tree-node:before {
  border-left: 1px dashed #b8b9bb;
  bottom: 0px;
  height: 100%;
  top: -20px;
  width: 1px;
}

.tree /deep/ .el-tree-node:after {
  border-top: 1px dashed #b8b9bb;
  height: 20px;
  top: 20px;
  width: 24px;
}
.el-tree {
  /deep/ .el-icon-caret-right:before {
    // 未展开
    content: "\e723";
    color: #3388ff;
    font-size: 18px;
  }
  /deep/ .expanded:before {
    // 展开
    content: "\e722";
    font-size: 18px;
    color: #3388ff;
  }
  /deep/ .expanded {
    // 展开不旋转
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

/deep/.el-tree-node__content {
  height: 100%;
  align-items: center;
  font-size: 16px;
}
.tree {
  /deep/.el-tree {
    padding-top: 20px;
    height: 800px;
    border-radius: 10px;
    overflow:auto;
  }
}
/deep/.el-tree::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #3388ff;
}
/deep/.el-tree::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
}
/deep/.el-tree::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
}
/deep/.el-tree-node:focus>.el-tree-node__content{
  color: #1677FF;
}
/deep/.el-tree-node.is-current > .el-tree-node__content {
  color: #1677FF;
}
</style>
